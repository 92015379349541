import { Box } from "@material-ui/core";
import { AttachmentCards } from "./AttachmentCards";
import React from "react";

export const Attachments = ({
  title = "",
  attachments,
  hasText = false,
  hasDesc = true,
  className = "",
  body = "",
}) => {
  return (
    <section className={className}>
      <div className="attachment-section-inner-container">
        {title.length !== 0 && (
          <Box
            mt={{ sm: 1, lg: 0 }}
            pt={{ sm: 5, lg: 0 }}
            className={"attachment-title"}
          >
            <h4>{title}</h4>
          </Box>
        )}
        {body.length > 0 && (
          <Box className={"attachment-body"}>
            <div
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </Box>
        )}
        {attachments[0]?.mid && (
          <AttachmentCards
            cards={attachments}
            hasText={hasText}
            hasDesc={hasDesc}
          />
        )}
      </div>
    </section>
  );
};
