export interface AuthContext {
  token?: string;
  logoutToken?: string;
  user?: any;
  authenticated?: boolean;
  tokenType?: string;
  expiresIn?: number;
  accessToken?: string;
  refreshToken?: string;
  msg?: string;
  roles?: Array<string>;
  conditions?: Array<string>;
  email?: string;
}

export interface UserLoginData {
  csrf_token: string;
  current_user: {
    uid: string;
    name: string;
    condition: string;
    email: string;
  };
  logout_token: string;
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
  roles: Array<string>;
  email: string;
}

export function getUserData(): UserLoginData {
  const tokenString = localStorage.getItem("token") ?? null;

  const userData = tokenString ? JSON.parse(tokenString) : {};

  return userData;
}
