import React from "react";
import { Attachment } from "../../interfaces/Pages";
import AttachmentCard from "./AttachmentCard";

interface AttachmentCardsProps {
  cards: Array<Attachment>;
  hasText: boolean;
  hasDesc: boolean;
}

export const AttachmentCards = ({ cards }: AttachmentCardsProps) => {
  return (
    <div className="cards-wrapper attachment-cards-wrapper">
      {cards &&
        cards.map((card, i) => (
          <div className="card-wrapper" key={i}>
            <AttachmentCard
              img={card.field_thumbnail.url}
              title={card.field_title ? card.field_title : ""}
              key={i}
              url={card.field_media_document.url}
              target={""}
            />
          </div>
        ))}
    </div>
  );
};
