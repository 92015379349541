export enum FormComponent {
  Select = "Select",
  SelectDropdown = "SelectDropdown",
  DatePicker = "DatePicker",
  DateTimePicker = "DateTimePicker",
  TimePicker = "TimePicker",
  TextField = "TextField",
  CheckBox = "Checkbox",
  AddressAutoComplete = "AddressAutoComplete",
  Toggle = "Toggle",
  TextArea = "TextArea",
  PlainText = "PlainText",
  HiddenTextField = "HiddenTextField",
}
