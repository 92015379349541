import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonWithLoader from "../../component/ButtonWithLoader";
import { InverseButton } from "../../component/InverseButton";
import { PageType, MedicationEditPageType } from "../../interfaces/Pages";
import { ToolBoxDataType } from "../../interfaces/ToolBoxData";
import { contentState, loadingState } from "../../store/Reducer";
import {
  createToolBoxData,
  getCreateFormStructure,
  getLoadingData,
} from "../../util/util";
import React from "react";
import DynamicForm from "../../component/Form/DynamicForm";
import { ATTEMPT_CREATE_TOOLBOX_ITEM } from "../../constants/constants";
import { useTranslation } from "react-i18next";

interface ToolBoxCreateFormProps {
  dataType: ToolBoxDataType;
  pageType: PageType;
  parentUrl: string;
  createFn: (data) => void;
  onClose?: () => void;
  options?: object;
}

export default function ToolBoxCreateForm({
  createFn,
  dataType,
  pageType,
  parentUrl,
  onClose,
}: ToolBoxCreateFormProps) {
  const content: MedicationEditPageType = useSelector(contentState)[pageType];
  const history = useHistory();
  const { isLoading, isSuccess } = getLoadingData(
    useSelector(loadingState),
    ATTEMPT_CREATE_TOOLBOX_ITEM
  );

  const callback = () => {
    if (onClose) onClose();
  };

  const formStructure = React.useMemo(() => {
    return getCreateFormStructure(dataType, content);
  }, [dataType, content]);

  const handleSubmit = (data) => {
    createToolBoxData(
      createFn,
      ATTEMPT_CREATE_TOOLBOX_ITEM,
      data,
      dataType,
      history,
      parentUrl,
      callback
    );
  };

  if (!formStructure) return <></>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  return (
    <DynamicForm
      formStructure={formStructure}
      onSubmit={handleSubmit}
      formClassName={`edit-form toolbox ${dataType} create`}
      renderButton={() => (
        <Box className="ActionButton__Wrapper">
          <InverseButton onClick={onClose}>
            {t("deleteDialog.cancel")}
          </InverseButton>
          <ButtonWithLoader
            type="submit"
            color="primary"
            aria-label={t("saveDialog.save")}
            loading={isLoading}
            success={isSuccess}
          >
            {t("saveDialog.save")}
          </ButtonWithLoader>
        </Box>
      )}
    />
  );
}
