import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";

const useStyles = makeStyles((theme) => ({
  spinner: {
    marginLeft: "15px",
  },
  checkIcon: {
    color: "green",
    marginLeft: "15px",
  },
}));

interface ButtonWithLoaderProps {
  [x: string]: any;
  children: any;
  loading: boolean;
  success: boolean;
}

const ButtonWithLoader = ({
  children,
  loading,
  success,
  disabled,
  ...rest
}: ButtonWithLoaderProps) => {
  const classes = useStyles();

  return (
    <Button disabled={disabled || loading} {...rest}>
      <div className="button-label">{children}</div>
      {loading && (
        <CircularProgress
          className={classes.spinner}
          size={20}
          color="secondary"
        />
      )}
      {success && <CheckIcon className={classes.checkIcon} />}
    </Button>
  );
};

export default ButtonWithLoader;
