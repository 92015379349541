import React from "react";

const useSuppressBodyScroll = () => {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
};

export default useSuppressBodyScroll;
