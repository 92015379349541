import React from "react";

import "../../assets/scss/components/SingleCard/SingleCard.scss";
import { MagnifyableImage } from "../MagnifyAbleImage";

const SingleCard = ({
  title = "",
  description = "",
  image = { url: "", alt: "" },
  className = "",
  imageAlignment = "",
  imageHeight = 0,
  description2 = "",
}) => {
  const noContent = title.length === 0 && description.length === 0;
  const hasTitle = image.alt !== "" && image.alt !== "-";
  if (noContent || !hasTitle) {
    if (className !== "") {
      className += " ";
    }
    className += "no-content";
  }

  if (imageAlignment !== "") {
    className += ` image-alignment-${imageAlignment}`;
  }

  if (imageAlignment !== "") {
    className += ` image-height-${imageHeight}`;
  }

  if (description.length > 0) {
    className += " has-body-content";
  }

  if (title.length > 0) {
    className += " has-title-content";
  }

  return (
    <div className={"single-card-outer-container"}>
      <div className={`single-card-container ${className}`}>
        <div
          className={`single-card-inner-container ${
            hasTitle ? "image-has-title" : ""
          }`}
        >
          <div className="single-card-text-container">
            <div className="single-card-title">{title}</div>
            {description.length !== 0 && (
              <div
                className="single-card-description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            {description2.length !== 0 && (
              <div
                className="single-card-description"
                dangerouslySetInnerHTML={{
                  __html: description2,
                }}
              />
            )}
          </div>
          {image.url !== "" && (
            <MagnifyableImage
              className={`single-card-image item-image ${
                noContent ? "full" : ""
              }`}
              src={image.url}
              alt={title}
              imageTitle={image.alt}
              right={!noContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
