import { Condition } from "./Condition";
import GoalType from "./GoalType";
import MedicationType from "./MedicationType";

export enum ToolBoxDataType {
  MEDICATIONS = "medications",
  GOALS = "goals",
  REMINDER = "reminder",
  BLOOD_RESULTS = "blood_results",
}

export interface ToolBoxData {
  [ToolBoxDataType.MEDICATIONS]: MedicationType[];
  [ToolBoxDataType.GOALS]: GoalType[];
  error?: string;
  msg?: string;
  formErrors?: Object | null;
  conditions: Array<Condition>;
}
