import ContentType from "../interfaces/ContentType";
import { PageType, SectionContent } from "../interfaces/Pages";

export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_TYPE = "GET_CONTENT_TYPE";
export const GET_TOOLBOX_CONTENT = "GET_TOOLBOX_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENTS = "UPDATE_CONTENTS";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";

export interface GetContentAction {
  type:
    | typeof GET_CONTENT
    | typeof GET_TOOLBOX_CONTENT
    | typeof GET_CONTENT_TYPE;
  page: PageType;
  pages?: Array<PageType>;
  content?: any;
  contentType?: ContentType;
  section?: SectionContent;
  sectionNumber?: number;
}

export interface GetContentTypeAction {
  type: typeof GET_CONTENT_TYPE;
  page: PageType;
  pages?: Array<PageType>;
  contentType: ContentType;
  content?: any;
  section?: SectionContent;
  sectionNumber?: number;
}

interface UpdatePagesContentAction {
  type:
    | typeof UPDATE_CONTENT
    | typeof UPDATE_CONTENTS
    | typeof UPDATE_SECTION
    | typeof UPDATE_ATTACHMENT;
  page: PageType;
  pages?: Array<PageType>;
  content?: any;
  section?: SectionContent;
  sectionNumber?: number;
}

export type ContentActions =
  | GetContentAction
  | UpdatePagesContentAction
  | GetContentTypeAction;

export function getContent(page: PageType): ContentActions {
  return {
    type: GET_CONTENT,
    page: page,
  };
}

export function getContentType(
  page: PageType,
  contentType: ContentType
): ContentActions {
  return {
    type: GET_CONTENT_TYPE,
    page: page,
    contentType: contentType,
  };
}

export function getToolBoxContent(): ContentActions {
  return {
    type: GET_TOOLBOX_CONTENT,
    page: PageType.toolbox,
  };
}

export function updateContent(page: PageType, content: any): ContentActions {
  return {
    type: UPDATE_CONTENT,
    page,
    content,
  };
}

export function updateContents(
  content: any,
  ...pages: Array<PageType>
): ContentActions {
  return {
    type: UPDATE_CONTENTS,
    page: PageType.none,
    pages,
    content,
  };
}

export function updateSection(
  page: PageType,
  section: SectionContent,
  sectionNumber: number
): ContentActions {
  return {
    type: UPDATE_SECTION,
    page,
    section,
    sectionNumber,
  };
}

export function updateAttachment(page: PageType, content: any): ContentActions {
  return {
    type: UPDATE_ATTACHMENT,
    page,
    content,
  };
}
