import { Box } from "@material-ui/core";
import { ReactNode, useState } from "react";
import "../../../assets/scss/Toolbox/MyProgress/MyProgress.scss";
import Dialog from "../../../component/Dialog";
import useDisclosure from "../../../hooks/useDisclosure";
import GoalType from "../../../interfaces/GoalType";
import MedicationType from "../../../interfaces/MedicationType";
import { convertDateToFormattedDateString } from "../../../util/util";
import { useTranslation } from "react-i18next";

interface ToolboxListProps {
  data: Array<MedicationType | GoalType>;
  valueState: number;
  dataType: string;
  dateKey?: string;
  children(id: string, onClose): ReactNode;
}

export const ToolboxList = ({
  data,
  valueState,
  dataType,
  dateKey = "date",
  children,
}: ToolboxListProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setSelectedId] = useState<string>("");

  const { t } = useTranslation();

  const listContent = {
    medication: {
      title: t("myProgress.leftSide.medicationSection.title"),
      emptyMessage: t("myProgress.leftSide.medicationSection.empty"),
      dialogTitle: t("myProgress.leftSide.medicationSection.dialogTitle"),
      dialogContentText: t(
        "myProgress.leftSide.medicationSection.dialogContentText"
      ),
    },
    goal: {
      title: t("myProgress.leftSide.goalSection.title"),
      emptyMessage: t("myProgress.leftSide.goalSection.empty"),
      dialogTitle: t("myProgress.leftSide.goalSection.dialogTitle"),
      dialogContentText: t("myProgress.leftSide.goalSection.dialogContentText"),
    },
  };

  return (
    <>
      <Box className={"toolbox-summary-container"}>
        <Box>
          <p className="toolbox-group body-16-bold">
            {listContent[dataType].title}
          </p>
          {data.length === 0 && (
            <p className="empty-message">
              {listContent[dataType].emptyMessage}
            </p>
          )}
          {data.map((entity) => (
            <div className="list-item flex">
              <button
                onClick={() => {
                  setSelectedId(entity.id.toString());
                  onOpen();
                }}
              >
                <span className="name flex-grow">
                  {entity.name} {t("myProgress.isDue")}{" "}
                  {valueState === 1
                    ? ` on ${convertDateToFormattedDateString(
                        new Date(entity[dateKey])
                      )}`
                    : ""}
                </span>
              </button>
            </div>
          ))}
        </Box>
        <Dialog
          className="Dialog__Toolbox"
          open={isOpen}
          title={listContent[dataType].dialogTitle}
          contentText={listContent[dataType].dialogContentText}
          renderContent={children(id, onClose)}
        />
      </Box>
    </>
  );
};
