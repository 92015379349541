import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/scss/Login/Login.scss";
import "../../assets/scss/Register/common/Common.scss";
import Footer from "../../component/Footer/Footer";
import { PageType } from "../../interfaces/Pages";
import { getContent } from "../../store/PageContentActions";
import { contentState } from "../../store/Reducer";
import RegisterBoard from "../Login/RegisterBoard";

const RegisterFormMain = () => {
  //const history = useHistory();
  const dispatch = useDispatch();
  const content = useSelector(contentState);

  useEffect(() => {
    dispatch(getContent(PageType.login));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={0} className="register-main-page">
        <Grid item md={12} lg={7} className="register-card">
          <RegisterBoard {...content.login} />
        </Grid>
      </Grid>
      <Footer legal_number={content?.legal_number} />
    </>
  );
};

export default RegisterFormMain;
