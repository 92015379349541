import { IconButton } from "@material-ui/core";
import React from "react";
import "../../assets/scss/Nav/Nav.scss";

interface HamburgerMenuProps {
  onOpen: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function HamburgerMenu({ onOpen }: HamburgerMenuProps) {
  const hardcodedLabel = "Hamburger menu";

  return (
    <IconButton
      onClick={onOpen}
      color="inherit"
      className={"hamburger-menu"}
      aria-label={hardcodedLabel}
    >
      <div className="hamburger-menu-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </IconButton>
  );
}
