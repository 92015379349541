import { Box } from "@material-ui/core";
import { Image } from "../../interfaces/Pages";

interface ColumnItemProps {
  img: Image;
  title?: string;
  desc?: string;
  subtitle?: string;
  onClick?: () => void;
}

export const ColumnItem = ({
  title = "",
  desc = "",
  subtitle = "",
  img,
  onClick,
}: ColumnItemProps) => {
  return (
    <div onClick={onClick}>
      <Box mb={13}>
        <Box mb={10} className="text-container">
          <h4 className="title">{title}</h4>
          <h4 className="subtitle font-weight-normal">{subtitle}</h4>
          <div className="body-18" dangerouslySetInnerHTML={{ __html: desc }} />
        </Box>
        <img src={img?.url} alt={img?.alt} />
      </Box>
    </div>
  );
};
