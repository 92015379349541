import React from "react";
import RightArrow from "../../resources/images/common/icon-feather-arrow-right.png";
import { useTranslation } from "react-i18next";

export const AttachmentCard = ({
  target,
  img,
  title,
  url,
  isAttachment = false,
}) => {
  const inputProps = {
    target: target ?? null,
  };

  const { t } = useTranslation();

  return (
    <div className="card attachment">
      <a
        href={url}
        {...inputProps}
        className={"link"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={img} alt={title} />
        <div className="content">
          <div className="content-title">{title}</div>
        </div>
        <div className="read-more">
          <div className="text">{t("card.readMore.label")}</div>
          <div className="arrow-container">
            <img src={RightArrow} alt="" />
          </div>
        </div>
      </a>
    </div>
  );
};

export default AttachmentCard;
