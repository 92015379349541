import { SymptomGalleryItem } from "../../interfaces/Pages";
import { ColumnItem } from "./ColumnItem";

interface SectionColumnsProps {
  columnItems: SymptomGalleryItem[];
}

export const SectionColumns = ({ columnItems }: SectionColumnsProps) => {
  return (
    <div className="column-container">
      {columnItems.map((column, i) => (
        <ColumnItem
          desc={column.field_articulate_360?.body}
          title={column.field_articulate_360?.field_title}
          img={column.field_symptom_gallery_item_image}
          subtitle={column.field_articulate_360?.field_subtitle}
          key={i}
        />
      ))}
    </div>
  );
};
