const ProsConsBox = ({ title = "", body = "", icon = true }) => {
  let classes = ["pros-cons-box"];
  if (icon) {
    classes = [...classes, "pro-icon"];
  } else {
    classes = [...classes, "con-icon"];
  }

  return (
    <section className={classes.join(" ")}>
      {title && (
        <div className="header-wrapper">
          <div className="header-icon" />
          <h4
            className="simple-section-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      )}
      {body && (
        <div
          className="body-18 description-container"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </section>
  );
};

export default ProsConsBox;
