import { useEffect, useState } from "react";
import { SymptomGalleryItem } from "../../interfaces/Pages";
import { ColumnItem } from "./ColumnItem";
import { SectionIFrame } from "./SectionIFrame";

interface SectionColumnsProps {
  modules: SymptomGalleryItem[];
}

export const CoachingSection = ({ modules }: SectionColumnsProps) => {
  const [activeModule, setActiveModule] = useState<SymptomGalleryItem | null>(
    null
  );

  const sortModules = () => {
    return modules.sort(sortAlphaNum);
  };

  const sortAlphaNum = (a, b) =>
    a.title.localeCompare(b.title, "en", { numeric: true });

  useEffect(() => {
    if (modules && !activeModule) {
      setActiveModule(sortModules()[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModule, modules]);

  if (modules.length === 0 || !activeModule) {
    return <></>;
  }

  return (
    <div className="section-article-container">
      <SectionIFrame
        title={activeModule.field_articulate_360?.field_title}
        subtitle={activeModule.field_articulate_360?.field_subtitle}
        desc={activeModule.field_articulate_360?.body}
        url={
          activeModule.field_articulate_360?.field_course_content_path +
          "/story_html5.html"
        }
      />
      <div className="column-container">
        {sortModules()
          .filter(
            (item, i) =>
              activeModule.field_articulate_360?.field_title !==
              item.field_articulate_360?.field_title
          )
          .map((module, i) => (
            <ColumnItem
              desc={module.field_articulate_360?.body}
              title={module.field_articulate_360?.field_title}
              img={module.field_symptom_gallery_item_image}
              subtitle={module.field_articulate_360?.field_subtitle}
              key={i}
              onClick={() => setActiveModule(module)}
            />
          ))}
      </div>
    </div>
  );
};
