import React from "react";

const ToolboxPdfCard = (props) => {
  return (
    <div className="toolbox-pdf-section-card">
      <div className="toolbox-pdf-section-card-image-container">
        <img
          className="toolbox-pdf-section-card-image"
          src={props.icon}
          alt=""
        />
      </div>
      <div className="toolbox-pdf-section-card-title">{props.title}</div>
      <div
        className="toolbox-pdf-section-card-description"
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
      <div className="toolbox-pdf-section-card-cta-container">
        <a
          href={props.buttonHref}
          download
          target="blank"
          className="toolbox-pdf-section-card-cta-button"
        >
          {props.buttonLabel}
        </a>
      </div>
    </div>
  );
};

export default ToolboxPdfCard;
