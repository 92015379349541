import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";
import { loadingState } from "../store/Reducer";
import { useLocation } from "react-router-dom";
import { LoadingDataType } from "../interfaces/LoadingData";
import { PageType } from "../interfaces/Pages";

interface WithLoaderProps {
  controlLoading?: boolean;
  loading?: boolean;
  children: any;
}

export default function WithLoader({
  children,
  controlLoading,
  loading,
}: WithLoaderProps) {
  const currentLoadingState: LoadingDataType = useSelector(loadingState);
  const location = useLocation().pathname.replace("/", "");

  const currentLoading =
    (controlLoading && loading) ||
    currentLoadingState.loading.find((loadingPage) =>
      loadingPage.includes(location)
    ) ||
    (currentLoadingState.loading.includes(PageType.toolbox) &&
      location.includes(PageType.toolbox)) ||
    currentLoadingState.loading.includes(PageType.general);

  return (
    <>
      {currentLoading && (
        <div className="backdrop">
          <CircularProgress className="loader" />
        </div>
      )}
      {children}
    </>
  );
}
