import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import { groupBy } from "../../../util/util";
import ToolBoxSettingsPage from "../ToolBoxSettingsPage";

export const GoalSettingWrapper = ({ editPageUrl }) => {
  return (
    <ToolBoxSettingsPage
      dataType={ToolBoxDataType.GOALS}
      editPageUrl={editPageUrl}
      hasTabs
      canAddCustom={true}
      noHashtag={true}
      groupData={(data) => {
        const grouped = groupBy(data, "status");

        if (!grouped[0]) {
          grouped[0] = [];
        }
        if (!grouped[1]) {
          grouped[1] = [];
        }

        if (!grouped[2]) {
          grouped[2] = [];
        }

        return grouped;
      }}
      isMedication={false}
    />
  );
};
