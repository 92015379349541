import { MenuItem, TextField } from "@material-ui/core";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../../assets/scss/formElements/Select.scss";

interface SelectProps {
  values: Array<any>;
  labels: Array<string>;
  defaultValue?: any | undefined | null;
  onChange: (value) => void;
  label?: string;
}

export const Select = ({
  values,
  labels,
  defaultValue = undefined,
  onChange,
  label = ""
}: SelectProps) => {
  const [value, setValue] = useState(defaultValue || values[0]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value);

    onChange(event.target.value);
  };

  return (
    <TextField
      id={"interval-select"}
      select
      label={label}
      variant="outlined"
      margin="normal"
      className={`select`}
      value={value}
      onChange={handleChange}
      SelectProps={{
        IconComponent: () => <ExpandMoreIcon className="Select__Icon" />,
      }}
    >
      {values.map((interval, index) => (
        <MenuItem value={interval} key={index}>
          {labels[index]}
        </MenuItem>
      ))}
    </TextField>
  );
};
