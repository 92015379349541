import { CTASmall } from "../CTA/CTASmall";
import { MagnifyableImage } from "../MagnifyAbleImage";
import { StyledSectionImage } from "./SectionImage/StyledSectionImage";

const defaultImg = { url: "", alt: "", title: "" };

export const Section = ({
  title = "",
  desc = "",
  subtitle = "",
  link = "",
  linkLabel = "",
  image = defaultImg,
  className = "",
}) => {
  const hasContent = !(
    title.length === 0 &&
    subtitle.length === 0 &&
    desc.length === 0 &&
    link.length === 0 &&
    linkLabel.length === 0
  );

  let classes = ["simple-section"];
  if (className !== "") {
    classes = [...classes, className];
  }

  const sectionImage = classes.includes("image-container") ? (
    <StyledSectionImage
      src={image.url as string}
      alt={image.alt as string}
      imageTitle={image.title as string}
      className="item-image"
    />
  ) : (
    <MagnifyableImage
      src={image.url as string}
      alt={image.alt as string}
      imageTitle={image.title as string}
      className="item-image"
    />
  );

  return (
    <>
      {hasContent && (
        <section className={classes.join(" ")}>
          {title && (
            <h4
              className="simple-section-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {subtitle && (
            <h4
              className="simple-section-subtitle"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
          {desc && (
            <div
              className="body-18 description-container"
              dangerouslySetInnerHTML={{ __html: desc }}
            />
          )}
          {linkLabel && <CTASmall link={link} linkLabel={linkLabel} />}
          {image?.url?.length !== 0 && sectionImage}
        </section>
      )}
    </>
  );
};
