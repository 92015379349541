import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Api } from "../../../api/Api";
import { PageType } from "../../../interfaces/Pages";
import { ToolBoxDataType } from "../../../interfaces/ToolBoxData";
import ToolBoxCreateForm from "../ToolBoxCreateForm";

interface MedicationCreationFormProps {
  onClose?: () => void;
}

export default function MedicationCreationForm({
  onClose,
}: MedicationCreationFormProps) {
  const { pathname } = useLocation();

  return (
    <Box>
      <ToolBoxCreateForm
        options={{}}
        createFn={Api.createMedication}
        pageType={PageType.medicationLoggerCreateMedication}
        dataType={ToolBoxDataType.MEDICATIONS}
        parentUrl={pathname}
        onClose={onClose}
      />
    </Box>
  );
}
