import React from "react";

import "../../assets/scss/components/SingleCard/SingleCard.scss";
import {SectionCarousel} from "../SectionArticle/SectionCarousel";

const SingleCardGallery = ({
  title = "",
  description = "",
  carousel = new Array<any>(),
  hasDesc = false,
  hasText = false,
  hasBody = false,
  className = "",
}) => {
  return (
    <div className={className}>
      <div className="single-card-gallery-container">
        <div className="single-card-text-container">
          {title.length !== 0 && (
            <div
              className="single-card-title"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
          {description.length !== 0 && (
            <div
              className="single-card-description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </div>
        <SectionCarousel
          carousel={carousel}
          desc=""
          hasDesc={hasDesc}
          hasText={hasText}
          hasBody={hasBody}
          title=""
        />
      </div>
    </div>
  );
};

export default SingleCardGallery;
