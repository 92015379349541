import * as yup from "yup";
import { FormComponent } from "../../../interfaces/FormComponent";
import { FormStructure } from "../../../interfaces/FormStructure";
import GoalType from "../../../interfaces/GoalType";
import { GoalEditPageType } from "../../../interfaces/Pages";

import i18next from "i18next";

export const getEditGoalFormStructure = (
  content: GoalEditPageType,
  goal: GoalType
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("goalFormStructure.name.label"),
        name: "field_goal_name",
        options: content.suggested_goal.map((goal) => ({
          id: goal.title,
          label: goal.title,
        })),
        defaultValue: goal?.name,
        noAnimation: true,
      },
      {
        component: FormComponent.DatePicker,
        label: i18next.t("goalFormStructure.date.label"),
        name: "field_goal_target_date",
        disablePast: true,
        type: "text",
        defaultValue: goal?.date,
      },
      {
        component: FormComponent.TimePicker,
        label: i18next.t("goalFormStructure.time.label"),
        defaultValue: goal?.date,
        name: "field_goal_target_date",
        type: "text",
      },
      {
        component: FormComponent.Toggle,
        label: i18next.t("goalFormStructure.checkbox.label"),
        defaultChecked: goal?.status === "1" ? true : false,
        name: "field_goal_status",
        type: "checkbox",
      },
    ],
    schema: yup.object().shape({
      field_goal_name: yup.string().required(i18next.t("error.required.goal")),
      field_goal_target_date: yup
        .date()
        .required(i18next.t("error.required.date")),
      field_goal_status: yup.boolean(),
    }),
  };
};

export const getCreateGoalFormStructure = (
  content: GoalEditPageType
): FormStructure => {
  return {
    components: [
      {
        component: FormComponent.SelectDropdown,
        label: i18next.t("goalFormStructure.name.label"),
        name: "field_goal_name",
        options: content.suggested_goal.map((goal) => ({
          id: goal.title,
          label: goal.title,
        })),
        placeholder: i18next.t("goalFormStructure.select"),
        defaultValue: "select",
        noAnimation: true,
      },
      {
        component: FormComponent.DatePicker,
        // @ts-ignore
        label: i18next.t("goalFormStructure.date.label"),
        name: "field_goal_target_date",
        disablePast: true,
        type: "text",
        defaultValue: new Date(),
      },
      {
        component: FormComponent.TimePicker,
        // @ts-ignore
        label: i18next.t("goalFormStructure.time.label"),
        name: "field_goal_target_date",
        type: "text",
        defaultValue: new Date(),
      },
    ],
    schema: yup.object().shape({
      field_goal_name: yup.string().required(i18next.t("error.required.goal")),
      field_goal_target_date: yup
        .date()
        .required(i18next.t("error.required.date")),
    }),
  };
};
