/* eslint-disable @typescript-eslint/no-array-constructor */
export interface LoadingDataType {
  loading: Array<string>;
  error: {
    [key: string]: {
      msg: string;
    };
  };
  success: {
    [key: string]: {
      msg: string;
    };
  };
}

export const initialLoadingData = {
  loading: [],
  error: {},
  success: {},
};
