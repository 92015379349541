import { default as React, InputHTMLAttributes } from "react";
import "../../assets/scss/formElements/Input.scss";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  noAnimation?: boolean;
  className: string;
  label: string | JSX.Element;
}

const CustomInput = React.forwardRef<any, CustomInputProps>(
  (
    {
      className,
      fullWidth = false,
      noAnimation = false,
      label,
      id,
      name,
      ...props
    },
    ref
  ) => {
    let classes = [className];
    if (fullWidth) {
      classes = [...classes, "full-width"];
    }

    return (
      <div className="input-container">
        <input className={classes.join(" ")} ref={ref} {...props} />
        <label
          htmlFor={id}
          className={`${noAnimation ? "" : "animated-label"}`}
        >
          {label}
        </label>
      </div>
    );
  }
);

export default CustomInput;
