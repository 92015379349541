export enum GAEventCategory {
    LOGIN = "login",
    PASSWORD_CHANGED = "password_change",
    REP_CALL_REQUESTED = "rep_call_request",
    COOL_BAG_ORDERED = "cool_bag_order",
    PASSWORD_RESET_REQUESTED = "password_reset_request",
    KIT_ORDERED = "kit_order",
    PROFILE_CHANGED = "profile_change",
    HCP_REGISTERED = "hcp_register",
    PATIENT_REGISTERED = "patient_register",
    MEDICATION_CREATED = "medication_creation",
    GOAL_CREATED = "goal_creation",
    MEDICATION_MODIFIED = "medication_modification",
    GOAL_MODIFIED = "goal_modification",
    MEDICATION_DELETED = "medication_deletion",
    GOAL_DELETED = "goal_deletion",
    REMINDER_CHANGED = "reminder_change"
}

export enum GAEvent {
    FORM_SUBMISSION = "form_submission",
}