import * as yup from "yup";
import {
  email,
  firstName,
  healthcare_organization,
  lastName,
  message,
  title,
} from "../../constants/constants";
import { FormComponent } from "../../interfaces/FormComponent";
import { FormStructure, Option } from "../../interfaces/FormStructure";

export const getContactFormStructure = (
  questionTypes: Array<Option>,
  titles: Array<Option>,
  t
): FormStructure => {

  return {
    components: [
      {
        component: FormComponent.Select,
        label: t("contactFormStructure.fields.title"),
        name: "field_name_title",
        type: "text",
        options: titles.map((title) => ({ id: title.id, label: title.label })),
        autoComplete: "honorific-prefix",
        defaultValue: "",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("contactFormStructure.fields.email"),
        name: "field_email",
        type: "text",
        autoComplete: "email",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("contactFormStructure.fields.firstName"),
        name: "field_first_name",
        type: "text",
        autoComplete: "given-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("contactFormStructure.fields.lastName"),
        name: "field_last_name",
        type: "text",
        autoComplete: "family-name",
        placeholder: " ",
        required: true,
      },
      {
        component: FormComponent.TextField,
        label: t("contactFormStructure.fields.organization"),
        name: "field_organization",
        type: "text",
        autoComplete: "organization",
        placeholder: " ",
        required: true,
      },

      {
        component: FormComponent.TextField,
        label: t("contactFormStructure.fields.profession"),
        name: "field_profession",
        type: "text",
        placeholder: " ",
      },
      {
        component: FormComponent.Select,
        label: t("contactFormStructure.fields.questionType"),
        name: "field_question_type",
        type: "text",
        defaultValue: "",
        options: questionTypes.map((questionType) => ({
          id: questionType.id,
          label: questionType.label,
        })),
        required: true,
      },
      {
        component: FormComponent.TextArea,
        label: t("contactFormStructure.fields.message"),
        name: "field_message",
        type: "text",
        required: true,
      },
    ],
    schema: yup.object().shape({
      field_name_title: title,
      field_email: email,
      field_message: message,
      field_first_name: firstName,
      field_last_name: lastName,
      field_organization: healthcare_organization,
      field_profession: yup.string(),
      field_question_type: yup.string().required(t("yup.contactForm.questionType")),
    }),
    title: t("contactFormStructure.title"),
    submitLabel: t("contactFormStructure.submit"),
  };
};
