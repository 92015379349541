import React from "react";
import "../../assets/scss/components/CTA/CTA.scss";
import CTAItem from "../../interfaces/CTAType";
import { PrivateLink } from "../../routing/PrivateLink";

const CTA = ({ button_label, button_url, description, title }: CTAItem) => {
  let buttonUrl = "#";
  if (button_url) {
    buttonUrl = button_url;
  }
  return (
    <>
      <div className="cta-container">
        <h4 className="cta-title">{title}</h4>
        <div
          className="cta-description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <PrivateLink className="cta-button" to={buttonUrl}>
          <span>{button_label}</span>
        </PrivateLink>
      </div>
    </>
  );
};

export default CTA;
